var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseContainer',[_c('span',{attrs:{"slot":"principal"},slot:"principal"},[_c('v-content',{staticClass:"pr-2 pl-2",staticStyle:{"background-color":"#555"}},[_c('v-toolbar',{staticClass:"ma-2",staticStyle:{"border-radius":"30px"}},[_c('v-btn',{attrs:{"icon":"","link":"","to":"/"}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-spacer'),_c('v-toolbar-title',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndUp),expression:"$vuetify.breakpoint.smAndUp"}]},[_vm._v("Faturas")]),_c('v-spacer'),_c('v-text-field',{attrs:{"dense":"","clearable":"","outlined":"","single-line":"","hide-details":"","label":"Pesquise..","append-icon":"mdi-magnify"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.consultar.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('PeriodoFilter',{on:{"update":_vm.consultar},model:{value:(_vm.periodo),callback:function ($$v) {_vm.periodo=$$v},expression:"periodo"}})],1),_c('v-card',{staticClass:"ma-1",attrs:{"elevation":"2"}},[_c('v-card-text',[_c('v-data-table',{attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.dados,"mobileBreakpoint":0,"loading":_vm.loading,"height":_vm.$vuetify.breakpoint.height - 260,"disable-pagination":true,"hide-default-footer":true,"options":_vm.options},on:{"click:row":_vm.editar,"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.fantasia",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.assinatura.empresa.id)+" - "+_vm._s(item.assinatura.empresa.fantasia)+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm.formatPrice(item.total))+" ")]}},{key:"item.total_pago",fn:function(ref){
var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm.formatPrice(item.total_pago))+" ")]}},{key:"item.vencimento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.vencimento))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}}])}),_c('div',{staticClass:"my-1 mx-2",staticStyle:{"white-space":"nowrap"}},[_c('b',[_vm._v("Total Pago:")]),_vm._v(" R$ "+_vm._s(_vm.formatPrice(_vm.pagination.amount || 0))+" ")]),_c('Paginate',{on:{"input":_vm.consultar},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }