<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #555;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">Faturas</v-toolbar-title>
                    <v-spacer/>
                    <v-text-field
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        @keypress.enter="consultar"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    ></v-text-field>
                    <v-spacer/>
                    <PeriodoFilter
                        v-model="periodo"
                        @update="consultar"
                    />
                    <!-- <v-btn color="primary" fab small @click="novo">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn> -->
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :height="$vuetify.breakpoint.height - 260"
                            @click:row="editar"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            :options.sync="options"
                        >
                            <template v-slot:item.fantasia="{ item }">
                                {{ item.assinatura.empresa.id }} - {{ item.assinatura.empresa.fantasia }}
                            </template>
                            <template v-slot:item.total="{ item }">
                                R$ {{ formatPrice(item.total) }}
                            </template>
                            <template v-slot:item.total_pago="{ item }">
                                R$ {{ formatPrice(item.total_pago) }}
                            </template>
                            <template v-slot:item.vencimento="{ item }">
                                {{ formatDate(item.vencimento) }}
                            </template>
                            <template v-slot:item.created_at="{ item }">
                                {{ formatDate(item.created_at) }}
                            </template>
                        </v-data-table>
                        <div class="my-1 mx-2" style="white-space: nowrap;">
                            <b>Total Pago:</b> R$ {{ formatPrice(pagination.amount || 0) }}
                        </div>
                        <Paginate
                            v-model="pagination"
                            @input="consultar"
                        />
                    </v-card-text>
                </v-card>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import Paginate from '@/components/Paginate';
import PeriodoFilter from '@/components/PeriodoFilter';
import { mapState } from 'vuex';

export default {
    name: 'Faturas',

    components: {
        BaseContainer,
        Paginate,
        PeriodoFilter,
    },

    data: () => ({
        search: '',
        visible: false,
        loading: false,
        dados: [],
        periodo: {},
        headers: [
            { text: 'ID', value: 'id' },
            { text: 'Efi', value: 'external_id' },
            { text: 'Empresa', value: 'fantasia' },
            { text: 'Total', value: 'total' },
            { text: 'Tot. Pago', value: 'total_pago' },
            { text: 'Status', value: 'status' },
            { text: 'Vencimento', value: 'vencimento' },
            { text: 'Data/hora', value: 'created_at' },
        ],
        pagination: {
            current_page: 1,
            last_page: 1,
            total: 0,
        },
        options: {},
    }),

    mounted() {
        this.consultar();
    },

    computed: {
        ...mapState([
            'usuario',
        ]),

        isDiretor() {
            return this.usuario.tipo === 'diretor';
        },
    },

    watch: {
        options: {
            async handler() {
                this.consultar();
            },
            deep: true,
        },
    },

    methods: {
        getParams() {
            const params = new URLSearchParams();

            params.append('start', this.periodo.start);
            params.append('end', this.periodo.end);

            params.append('q', this.search || '');
            params.append('page', this.pagination.current_page);

            if (this.options.sortBy.length) {
                params.append('sortField', this.options.sortBy[0]);
            }
            if (this.options.sortDesc.length) {
                params.append('sortOrder', this.options.sortDesc[0] ? 1 : -1);
            }

            return params.toString();
        },

        consultar() {
            if (!this.isDiretor) {
                return;
            }

            this.loading = true;
            this.dados = [];

            const params = this.getParams();

            this.$http.get(`cobrancas?${params}`).then(resp => {
                this.pagination = resp.data.meta;
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                alert('Não foi possivel concluir a operação');
                this.loading = false;
            });
        },

        editar(row) {
            console.log(row)
            // this.$store.commit('setEmpresa', row);
            // this.$router.push('/financeiro');
        },
    },
}
</script>
