<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #555;">
                <div class="chat-container">
                    <v-card class="chat-list">
                        <v-card-title>
                            <span class="font-weight-bold">Chats</span>
                        </v-card-title>
                        <v-divider/>
                        <v-list @scroll="onScrollChats" class="chat-list-container">
                            <template v-if="chatsLoading">
                                <v-skeleton-loader v-for="i in 5" :key="i" type="list-item-avatar"/>
                            </template>
                            <v-list-item
                                v-for="chat in chats"
                                :key="chat.id"
                                @click="selectChat(chat)"
                                :class="{ 'active-chat': selectedChat && selectedChat.id === chat.id }"
                            >
                                <v-list-item-avatar>
                                    <v-icon>mdi-account-circle</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ getContactName(chat) }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ chat.message }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <span class="caption">
                                        {{ formatDate(chat.created_at, 'HH:mm') }}
                                        <v-icon v-if="chat.sent_by_me && !chat.read_at">mdi-circle</v-icon>
                                    </span>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card>

                    <v-card v-if="selectedChat" class="chat-messages">
                        <v-card-title>
                            <v-avatar class="mr-3">
                                <v-icon>mdi-account-circle</v-icon>
                            </v-avatar>
                            <div>
                                <span class="font-weight-bold">
                                    {{ getContactName(selectedChat) }}
                                </span>
                            </div>
                        </v-card-title>
                        <v-divider/>
                        <v-card-text
                            ref="chatBody"
                            class="chat-body"
                            @scroll="onScrollMessages"
                        >
                            <template v-if="messagesLoading">
                                <v-skeleton-loader v-for="i in 5" :key="i" type="text"/>
                            </template>
                            <div
                                v-for="msg in messages"
                                :key="msg.id"
                                class="message"
                                :class="{ sent: !msg.sentByMe, received: msg.sentByMe }"
                            >
                                <div class="message-content">
                                    <p v-html="formatMessage(msg.text)"/>
                                    <div class="message-meta">
                                        <small>{{ formatDate(msg.time, 'HH:mm') }}</small>
                                        <v-icon v-if="!msg.sentByMe" small :color="msg.read ? 'blue' : 'gray'">
                                            mdi-check-all
                                        </v-icon>
                                    </div>
                                </div>
                            </div>
                        </v-card-text>
                        <v-divider/>
                        <v-card-actions>
                            <v-textarea
                                ref="messageInput"
                                v-model="messageInput"
                                placeholder="Digite uma mensagem"
                                auto-grow
                                rows="1"
                                outlined
                                dense
                                hide-details
                                @keyup.enter.prevent="pressEnter"
                                :disabled="sendLoading"
                            />
                            <v-btn icon color="primary" @click="sendMessage" :loading="sendLoading">
                                <v-icon>mdi-send</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <v-card v-else class="chat-messages">
                        <v-card-title>Selecione um chat</v-card-title>

                    </v-card>
                </div>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';

export default {
    name: 'Chat',

    components: {
        BaseContainer,
    },

    data: () => ({
        chats: [],
        selectedChat: null,
        chatsLoading: false,
        sendLoading: false,
        pageChats: 1,
        totalPagesChats: 2,
        messages: [],
        messagesLoading: false,
        pageMessages: 1,
        totalPagesMessages: 2,
        messageInput: '',
        newMessageSoud: null,
    }),

    mounted() {
        this.consultarChats();

        document.addEventListener('chat-event', this.onChatEvent);

        this.newMessageSoud = new Audio(require('@/assets/sounds/new_message.mp3'));
    },

    beforeDestroy() {
        this.removeListeners();
    },

    methods: {
        removeListeners() {
            document.removeEventListener('chat-event', this.onChatEvent);
        },

        getContactName(obj) {
            return obj?.empresa?.fantasia || 'Anônimo';
        },

        async onChatEvent({ detail }) {
            const { message, type } = detail;

            const formattedMessage = {
                id: message.id,
                text: message.message,
                time: message.created_at,
                sentByMe: message.sent_by_me,
                read: message.status === 'lido',
            };

            if (type === 'CHAT_READ') {
                const index = this.messages.findIndex(e => e.id === message.id);
                this.messages[index] = formattedMessage;
                this.$forceUpdate();
                return;
            }

            if (this.selectedChat?.sender_uuid == message.sender_uuid) {
                this.messages.push(formattedMessage);
                this.scrollToBottom();

                this.setMessagesRead();
            }

            const index = this.chats.findIndex(e => e.sender_uuid == message.sender_uuid);

            const chat = await this.getChatByUuid(message.sender_uuid);

            if (index !== -1) {
                this.chats[index] = chat;
            } else {
                this.chats.push(chat);
            }

            this.chats.sort((a, b) => this.moment(b.created_at).diff(this.moment(a.created_at)));

            this.$forceUpdate();

            if (message.sent_by_me) {
                this.newMessageSoud.play();
            }
        },

        setMessagesRead() {
            const data = {
                sender_uuid: this.selectedChat.sender_uuid,
                sent_by_me: true,
            };

            this.$http.post('chat/messages-set-read', data);
        },

        async getChatByUuid(uuid) {
            let data = null;

            await this.$http.get(`chat/${uuid}`).then(resp => {
                console.log('get ',resp.data);
                data = resp.data;
            }).catch(() => {
                this.notify('Não foi possível carregar os chats.', 'warning');
            });

            return data;
        },

        consultarChats() {
            if (this.chatsLoading) return;
            this.chatsLoading = true;

            const data = { page: this.pageChats };
            this.$http.post('chats', data).then(resp => {
                const newChats = resp.data.data;

                this.chats = [...this.chats, ...newChats];

                this.chats.sort((a, b) => this.moment(b.created_at).diff(this.moment(a.created_at)));

                this.totalPagesChats = resp.data.meta.last_page;
            }).catch(() => {
                this.notify('Não foi possível carregar os chats.', 'warning');
            }).finally(() => {
                this.chatsLoading = false;
            });
        },

        async consultarMensagens() {
            if (this.messagesLoading || !this.selectedChat) return;
            this.messagesLoading = true;

            const data = { page: this.pageMessages };
            await this.$http.post(`chats/${this.selectedChat.sender_uuid}/messages`, data).then(resp => {
                const newMessages = resp.data.data.map(msg => ({
                    id: msg.id,
                    text: msg.message,
                    time: msg.created_at,
                    sentByMe: msg.sent_by_me,
                    read: msg.status === 'lido',
                }));

                const messages =  this.pageMessages === 1
                    ? newMessages
                    : [...newMessages, ...this.messages];

                this.messages = messages.sort((a, b) => this.moment(a.time).diff(this.moment(b.time)));

                this.totalPagesMessages = resp.data.meta.last_page;
            }).catch(() => {
                this.notify('Não foi possível carregar as mensagens.', 'warning');
            }).finally(() => {
                this.messagesLoading = false;
            });
        },

        async selectChat(chat) {
            this.selectedChat = chat;
            this.messages = [];
            this.pageMessages = 1;

            await this.consultarMensagens();

            this.scrollToBottom();
            this.$refs.messageInput.focus();
        },

        onScrollChats(event) {
            const bottomOfList = event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight;

            if (bottomOfList && this.pageChats < this.totalPagesChats) {
                this.pageChats++;
                this.consultarChats();
            }
        },

        async onScrollMessages(event) {
            const topOfList = event.target.scrollTop === 0;

            if (topOfList && this.pageMessages < this.totalPagesMessages) {
                this.pageMessages++;
                const chatBody = this.$refs.chatBody;
                const scroll = chatBody.scrollHeight
                await this.consultarMensagens();

                chatBody.scrollTop = chatBody.scrollHeight - scroll;
            }
        },

        sendMessage() {
            const message = this.messageInput.trim();

            if (!message) return;

            const data = {
                message,
                sent_by_me: false,
                sender_uuid: this.selectedChat.sender_uuid,
            };

            this.sendLoading = true;
            this.$http.post('chat', data)
                .then(resp => {
                    if (resp.data.type == 'warning') {
                        this.notify(resp.data.msg, 'warning');
                        return;
                    }

                    this.messageInput = '';
                    this.scrollToBottom();
                })
                .catch(() => this.notify('Não foi possivel enviar', 'warning'))
                .finally(() => {
                    this.sendLoading = false;
                    setTimeout(() => this.$refs.messageInput.focus(), 100);
                });
        },

        setMessage(message) {
            const newMessage = {
                id: message.id,
                text: message.message,
                time: message.created_at,
                sentByMe: message.sent_by_me,
                read: message.status === 'lido',
            };

            const index = this.chats.findIndex(e => e.sender_uuid == message.sender_uuid);

            if (index !== -1) {
                this.chats[index] = message;
            }

            this.messages.push(newMessage);

            this.messageInput = '';
            this.scrollToBottom();
        },

        scrollToBottom() {
            this.$nextTick(() => {
                const chatBody = this.$refs.chatBody;
                if (chatBody) chatBody.scrollTop = chatBody.scrollHeight;
            });
        },

        formatMessage(text) {
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
        },

        pressEnter(event) {
            if (event.shiftKey) {
                return;
            }

            this.sendMessage();
        },
    },
};
</script>

<style scoped>
.chat-container {
    display: flex;
    height: 92vh;
}

.chat-list {
    width: 30%;
    border-right: 1px solid #ddd;
    overflow-y: auto;
}

.chat-messages {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.chat-body {
    flex-grow: 1;
    overflow-y: auto;
    /* max-height: 75vh; */
}

.message {
    display: flex;
    margin: 8px;
}

.message.sent {
    justify-content: flex-end;
}

.message.received {
    justify-content: flex-start;
}

.message-content {
    max-width: 70%;
    padding: 10px;
    border-radius: 10px;
    background-color: #4d4d4d;
    position: relative;
    white-space: pre-line;
}

.message.sent .message-content {
    background-color: #473f43;
}

.message-meta {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    font-size: 12px;
    margin-top: 4px;
}

.active-chat {
    background-color: #3d81ff;
}
</style>
